<template>
  <div>
    <UCard
        class="w-full h-screen md:h-full flex flex-col"
        :ui="
        props.isOverlay
        ? {
          header: {
            base: 'flex-none',
          },
          body: {
            base: 'flex-1 overflow-y-auto',
          },
          footer: {
            base: 'flex-none',
          },
        }
        : {
          header: {padding: 'px-2 py-2 sm:p-2 lg:p-4'},
          body: {padding: 'px-2 py-2 sm:px-2 lg:p-4'},
          footer: {padding: 'px-2 py-2 sm:px-2 lg:p-4'}
        }"
    >
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="flex gap-x-2 -text-base font-semibold leading-6 text-gray-900 dark:text-white items-center">
            <UIcon name="i-ri-equalizer-line"/>
            Filtry
          </h3>
          <UButton v-if="props.isOverlay" class="-my-1" color="gray" icon="i-ri-close-line"
                   variant="ghost" @click="emit('closeOverlay')"/>
        </div>
      </template>
      <UForm ref="formRef" :state="form" class="space-y-4" @submit="handleForm">
        <UFormGroup label="Kategoria">
          <URadio
              v-model="form.category"
              :disabled="form.isInProgress"
              class="mt-2"
              label="Wszystkie"
              name="category"
              value=""
          />
          <URadio
              v-for="category of categoryRepository.find().items"
              v-model="form.category"
              :disabled="form.isInProgress"
              :label="category.name"
              :value="category.id"
              class="mt-2"
              name="category"
          />
        </UFormGroup>
        <UFormGroup label="Miejscowść" hint="min. 3 znaki">
          <USelectMenu
              v-model="form.city"
              :disabled="form.isInProgress"
              :searchable="searchAddressByCity"
              icon="i-ri-building-line"
              placeholder="gdzie mamy szukać?"
              size="lg"
          />
        </UFormGroup>
        <UFormGroup label="Tagi" hint="min. 3 znaki">
          <USelectMenu
              v-model="form.tags"
              :disabled="form.isInProgress"
              :searchable="searchTagsByQuery"
              icon="i-ri-hashtag"
              multiple
              size="lg"
          >
            <template #label>
            <span v-if="form.tags.length" class="truncate">
              {{ form.tags.join(', ') }}
            </span>
              <span v-else>np. klucze, portfel, telefon</span>
            </template>
          </USelectMenu>
        </UFormGroup>
        <UFormGroup label="Kalendarz">
          <URadio
              v-for="category of [{type: 'createdAt', 'name': 'data publikacji'}, {type: 'occurredAt', 'name': 'data zdarzenia'}]"
              v-model="form.date.type"
              :disabled="form.isInProgress"
              :label="category.name"
              :value="category.type"
              class="mt-2"
              name="date"
          />
        </UFormGroup>
        <VCalendarDatePicker
            v-model.range="form.date.range"
            :disabled="form.isInProgress"
            :is-dark="{ selector: 'html', darkClass: 'dark' }"
            color="green"
            expanded
            transparent
        />
      </UForm>
      <template #footer>
        <UFormGroup>
          <UButton :loading="form.isInProgress" block label="Filtruj" size="md" type="submit" @click="handleForm"/>
        </UFormGroup>
        <UFormGroup>
          <UButton :loading="form.isInProgress" block class="mt-2" label="Resetuj" size="md" to="/" @click="emit('closeOverlay')" variant="ghost"/>
        </UFormGroup>
      </template>
    </UCard>
  </div>
</template>

<script setup lang="ts">
import 'v-calendar/style.css';
import {DatePicker as VCalendarDatePicker} from 'v-calendar'
import {useApi} from "~/composables/useApi";
import CategoryRepository from "~/repositories/CategoryRepository";

const props = defineProps({
  isOverlay: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['closeOverlay']);

const categoryRepository = new CategoryRepository();;

const computedDefaultPayload = await computed(() => {
  const {query} = useRoute();

  const dateType = query['filter[createdAt][from]']
      ? 'createdAt'
      : 'occurredAt';

  return {
    isInProgress: false,
    category: query['filter[category]'] ?? '',
    city: query['filter[city]'] ?? undefined,
    tags: query['filter[tags][]'] ?? [],
    date: {
      type: dateType,
      range: query[`filter[${dateType}][from]`]
          ? {start: new Date(query[`filter[${dateType}][from]`]), end: new Date(query[`filter[${dateType}][to]`])}
          : undefined,
    }
  };
});

const formRef = ref();
const form = ref(computedDefaultPayload);

const searchTagsByQuery = async (query) => {
  if (query && query.length < 3) {
    return [];
  }

  const {data} = await useApi('/rest/public/tags', {
    query: query ? {'filter[query]': query} : undefined,
  });

  return data.value.items.map((tag) => tag.slug);
};

const searchAddressByCity = async (query) => {
  if (query && query.length < 3) {
    return [];
  }

  const {data} = await useApi('/rest/public/cities', {
    query: query ? {'filter[query]': query} : undefined
  });

  return data.value.items.map(city => ({label: city.name}));
};

const router = useRouter();

const handleForm = async () => {
  form.value.isInProgress = true;

  const payload = form.value;
  const query = {};

  if (payload.category) {
    query['filter[category]'] = payload.category;
  }

  if (payload.city) {
    query['filter[city]'] = payload.city.label;
  }

  if (payload.tags.length) {
    query['filter[tags][]'] = payload.tags.map((tag) => tag);
  }

  if (payload.date.range) {
    query[`filter[${payload.date.type}][from]`] = new Date(payload.date.range.start).toISOString();
    query[`filter[${payload.date.type}][to]`] = new Date(payload.date.range.end).toISOString();
  }

  console.log(query);

  router.push({path: '/', query})
      .then(() => {
        emit('closeOverlay');
      })
      .finally(() => {
        form.value.isInProgress = false;
      })
  ;
};
</script>
